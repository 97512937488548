import React, { useState, useEffect } from "react";

import useQueries from "../../../hooks/useQueries";

import Annotation3D from "./annotation3D";

const Annotations3D = (props) => {
  const { numero, obj } = props;

  const { data: annotations, isSuccess: isAnnotations } = useQueries(
    `/json/annotations/${numero}.json`
  );

  return isAnnotations ? (
    <group name={`${numero}_annotations`}>
      {Object.keys(annotations?.annotations3D).map((annotation, index) => (
        <Annotation3D
          key={index}
          index={index}
          position={annotations?.annotations3D[annotation].target}
          obj={obj}
          numero={numero}
        />
      ))}
    </group>
  ) : null;
};

export default Annotations3D;

import React, { useEffect, useRef, useState } from "react";
import { useRoute, useLocation } from "wouter";
import * as jp from "jsonpath";

import useParseNotice from "./useParseNotice";

const xmlParserOptions = {
  ignoreAttributes: false,
  // ignoreNameSpace: true,
  trim: true,
  parseAttributeValue: true,
  stopNodes: [
    "*.p",
    // "*.head",
    "*.bibl",
    "*.index",
    "*.ref",
  ],
  processEntities: true,
  isArray: (name, jpath, isLeafNode, isAttribute) => {
    if (["children", "div", "p", "bibl", "hi"].indexOf(name) !== -1)
      return true;
  },
  // htmlEntities: true,
};

const useAnnotation2D = (fragment) => {
  const { isSuccess, xml } = useParseNotice(fragment, xmlParserOptions);
  let images;

  if (isSuccess && xml) {
    let annotations2D = jp.query(
      xml,
      `$..[?(@.@_type=="section2" && @.head=="UD 2D")]`
    )[0];

    images = annotations2D?.div;
  }

  return isSuccess && images
    ? { isSuccess, images }
    : { isSuccess: false, images: null };
};

export default useAnnotation2D;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLocation as useWocation, useRoute as useWoute } from "wouter";
import useHashLocation from "../hooks/useHashLocation";
import { X } from "@mui/icons-material";

const Collection = (props) => {
  const { translation, positions, size, shift, id, title, radius } = props;
  const [hovered, setHovered] = useState(false);
  const [selected, setSelected] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    navigate(`/${id}`);
  };
  const handleMouseOver = (e) => {
    e.stopPropagation();
    setHovered(true);
  };
  const handleMouseOut = (e) => {
    e.stopPropagation();
    setHovered(false);
  };

  useEffect(() => {
    if (location.pathname === `/${id}`) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [location]);

  const fill = hovered ? "orange" : selected ? "peru" : "#ffffff33";
  const stroke = hovered ? "orange" : selected ? "peru" : "#cccccc";

  // let maxX = Math.max(...positions.map((p) => p.x))

  let [maxX, maxY] = ["x", "y"].map((item, index) =>
    Math.max(...positions.map((p) => p[item] + 1))
  );

  return (
    <g
      id={id}
      transform={`translate(${translation.x} ${translation.y})`}
      className="fragments"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={handleClick}
    >
      <rect
        id="fond"
        // x={0}
        // y={0}
        x={-1.5}
        y={-1.5}
        // height={2.5 * shift.height}
        // width={2.5 * shift.width}
        height={maxY * shift.height}
        width={maxX * shift.width}
        fill="#ffffff00"
        stroke="none"
        rx={radius}
      />
      <g id="elements" fill={fill} stroke={stroke} className="fragments">
        {positions.map((r, i) => (
          <rect
            key={i}
            x={r.x * shift.height}
            y={r.y * shift.width}
            height={size.height}
            width={size.width}
            id={`rect-${i}`}
            rx={radius}
          />
        ))}
      </g>
      <title>{`vers la collection de ${title}`}</title>
    </g>
  );
};

export default Collection;

import React, { useState, useEffect } from "react";
import { ClickAwayListener } from "@mui/base";
import { Box, Stack, Fab, Badge, Tooltip } from "@mui/material";
import { useLocation } from "wouter";

import * as jp from "jsonpath";

import useAnnotation from "../../../hooks/useAnnotation";
import FragmentRadioAnnotation3D from "./fragmentRadioAnnotation3D";

const FragmentRadioAnnotations3D = (props) => {
  const { fragment } = props;

  const [activeButton, setActiveButton] = useState(null);
  const [location, setLocation] = useLocation();

  const { isSuccess, annotations } = useAnnotation(fragment);

  const handleActiveButton = (value) => {
    setActiveButton(value);
    setLocation(`/annotations-3D/annotation-${value + 1}`);
  };

  const handleClickAway = () => {
    console.log("click away");
    setActiveButton(null);
    // setLocation("");
  };

  return isSuccess && annotations?.length ? (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        right: 0,
        transform: `translateY(-50%)`,
        zIndex: 1000,
      }}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <Stack
          direction="column"
          sx={{ mx: 2, "& > :not(style)": { m: 1 } }}
          // onClick={handleClickAway}
        >
          {annotations?.map((annotation, index) => {
            return (
              <FragmentRadioAnnotation3D
                key={index}
                index={index}
                activeButton={activeButton}
                annotation={annotation}
                handleActiveButton={handleActiveButton}
              />
            );
          })}
        </Stack>
      </ClickAwayListener>
    </Box>
  ) : null;
};

export default FragmentRadioAnnotations3D;

import React from "react";
import { Box, Typography, Divider, Stack, Button } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const BulleNote = (props) => {
  return (
    <Box sx={{ pt: 1 }}>
      {props.text && (
        <>
          <Stack>
            <Typography variant="body2" paragraph align="justify">
              {props.text}
            </Typography>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default BulleNote;

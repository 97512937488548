import React, { useEffect, useRef, useState } from "react";
import { useRoute, useLocation } from "wouter";
import * as jp from "jsonpath";

import useParseNotice from "./useParseNotice";

const xmlParserOptions = {
  ignoreAttributes: false,
  // ignoreNameSpace: true,
  trim: true,
  // parseAttributeValue: true,
  stopNodes: [
    "*.p",
    // "*.head",
    "*.bibl",
    "*.index",
  ],
  processEntities: true,
  isArray: (name, jpath, isLeafNode, isAttribute) => {
    if (["children", "div", "p", "bibl", "hi"].indexOf(name) !== -1)
      return true;
  },
  // htmlEntities: true,
};

const useAnnotation = (fragment) => {
  const [match, params] = useRoute("/:section/:id");
  const [location, setLocation] = useLocation();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  let loc = location.split("/");

  const { isSuccess, xml } = useParseNotice(fragment, xmlParserOptions);

  let annotations;
  let definitions;
  if (isSuccess && xml) {
    let annotations3D = jp.query(
      xml,
      `$..[?(@.@_type=="section2" && @.head=="Annotations 3D")]`
    )[0];
    let entetes = annotations3D?.div.map((d) => d.head);
    let defs = annotations3D?.div.map((d) => d.p);

    entetes?.length === 1 && entetes[0] === undefined
      ? (annotations = null)
      : (annotations = entetes);

    defs?.length === 1 && defs[0] === undefined
      ? (definitions = null)
      : (definitions = defs);
  }

  return isSuccess && annotations && definitions
    ? { isSuccess, annotations, definitions }
    : { isSuccess: false, annotations: null, definitions: null };
};

export default useAnnotation;

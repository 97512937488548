import React, { useState, useEffect } from "react";
import {
  Drawer,
  Box,
  Toolbar,
  Typography,
  Modal,
  Grid,
  Backdrop,
  IconButton,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 5*24px)",
  height: "calc(100% - 5*24px)",
  bgcolor: "background.paper",
  padding: "12px 0px 12px 0px",
  borderRadius: "12px",
};

const voletStyle = {
  height: "80vh",

  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#00000050",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "00000050",
    backgroundColor: "#555",
    borderRadius: "2px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#888",
  },
};

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0.2 0.9 0.3 / 0.5);
`;

const FragmentAnnotations2D = () => {
  //   const { open, handleClose } = props;

  //   const [id, setId] = useState("intro");

  return (
    <Modal
      open={false}
      // onClose={handleClose}
      // slots={{ backdrop: StyledBackdrop }}
    >
      <Box sx={modalStyle}>
        <Toolbar variant="dense">
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="button">
              Le décor sculpté de la Tholos de Marmaria
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              size="small"
              //   onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Toolbar>
        <Grid container direction="row" alignItems="stretch"></Grid>
      </Box>
    </Modal>
  );
};

export default FragmentAnnotations2D;

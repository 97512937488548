import React, { useEffect, useState, Suspense } from "react";
import { Box, Skeleton } from "@mui/material";
import { useLocation } from "wouter";

import { useLoadImage, useQueries } from "../../../hooks";

import { convertNotation } from "../../../../outils";
import { use } from "react";

const Zoom = (props) => {
  const { drawerOpen, annotationId } = props;
  const { image, error, loading } = useLoadImage(props);

  //   console.log(annotationId);

  //
  const [location, setLocation] = useLocation();
  const [firstLoad, setFirstLoad] = useState(false);
  const img_privee = "/images/img_privee.jpg";
  const img_blank = "/modeles/placeholders/geometry_blank.png";

  const { data, isSuccess } = useQueries(
    `https://archimage.efa.gr/action.php?kroute=document_export_json_public&id=${props.archimageId}`
  );

  const { hex, couche } = convertNotation(props?.listNotation);

  useEffect(() => {
    if (drawerOpen) {
      couche && hex && setTimeout(() => setLocation(`/${couche}/${hex}`), 100);
    }

    !drawerOpen ? setTimeout(() => setLocation(""), 600) : null;

    return () => setFirstLoad(false);
  }, [hex, couche, drawerOpen]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "650px",
        bgcolor: "#212121",
        borderRadius: "4px",
        objectFit: "contain",
      }}
    >
      {/* <Skeleton variant="rounded" height="100%" /> */}

      {!loading && image ? (
        <img
          src={image}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            borderRadius: "4px",
          }}
        />
      ) : (
        <Skeleton variant="rounded" height="100%" />
      )}
    </Box>
  );
};

export default Zoom;

import React from "react";
import Stack from "@mui/material/Stack";
import Vignette from "./vignette";

const BandeauVignettes = (props) => {
  const { vignettes, setVignetteActive, vignetteActive, setAnnotationActive } =
    props;

  return (
    <Stack direction="row" spacing={1}>
      {vignettes.map((vignette, i) => (
        <Vignette
          key={i}
          id={i}
          archimageId={vignette.id}
          image={vignette.thumbUrl}
          setVignetteActive={setVignetteActive}
          vignetteActive={vignetteActive}
          setAnnotationActive={setAnnotationActive}
        />
      ))}
    </Stack>
  );
};

export default BandeauVignettes;

import React from "react";

import {
  Acroteres,
  Colonne,
  Fleuron,
  Crepis,
  Cella,
  Peristyle,
  Metopes,
  Tholos,
  Fragments,
  PetitesMetopes,
  GrandesMetopes,
  FragmentsAcroteres,
} from "./svg";

const Plan = () => {
  return (
    <div id="plan-svg">
      <svg
        id="plan-tholos"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="106 106 106 106"
        // viewBox="96 96 106 106"
        // viewBox="0 0 211.66666 211.66667"
        width="100%"
        height="100%"
        preserveAspectRatio="xMidYMid meet"
      >
        <Crepis />
        <Metopes />
        <Acroteres />
        <Fragments />
        <PetitesMetopes />
        <GrandesMetopes />
        <FragmentsAcroteres />
        {/* <Colonne /> */}
        {/* <Fleuron /> */}
      </svg>
    </div>
  );
};

export default Plan;

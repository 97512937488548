import React, { useState, useEffect, useLayoutEffect } from "react";
import { Tooltip } from "@mui/material";
import BulleNote from "./bulleNote";

const TooltipNote = (props) => {
  const { id, note, data } = props;

  return (
    <Tooltip
      id={id}
      title={data ? <BulleNote text={data} /> : null}
      arrow
      leaveDelay={250}
    >
      <sup className="note">&#8239;{note}</sup>
    </Tooltip>
  );
};

export default TooltipNote;

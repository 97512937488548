import React from "react";
import Head from "./head";

import Accordion from "@mui/material/Accordion";

import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { isArray } from "../../../outils";

import ReferenceBiblio from "./referenceBiblio";

const BibliographieAccordion = (props) => {
  // console.log(props);
  return (
    <Accordion
      key={props[`@_xml:id`]}
      id={props[`@_xml:id`]}
      type={props[`@_type`]}
      defaultExpanded={true}
    >
      <AccordionSummary>
        {props.head ? <Head text={props.head} variant="overline" /> : null}
      </AccordionSummary>
      <AccordionDetails>
        {props.bibl.map((b, index) => (
          <ReferenceBiblio
            key={b[`@_xml:id`]}
            id={b[`@_xml:id`]}
            text={b[`#text`]}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default BibliographieAccordion;

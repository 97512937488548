export const xmlParserOptions = {
  ignoreAttributes: false,
  attributeNamePrefix: "",
  trim: true,
  stopNodes: ["*.p", "*.head", "*.label", "*.bibl"],
  processEntities: true,
  ignoreDeclaration: true,
  ignorePiTags: true,
  removeNSPrefix: true,
  isArray: (name, jpath, isLeafNode, isAttribute) => {
    if (["children", "div", "p"].indexOf(name) !== -1) return true;
  },
  // transformTagName: (tagName) => setTagName(tagName),
  // attributeValueProcessor: (name, val, jPath) => {
  //   console.log(name, val);
  // },

  // tagValueProcessor: (tagName, tagValue, jPath, hasAttributes, isLeafNode) =>
  //   (tagValue = processTagValue(tagValue, jPath)),
};

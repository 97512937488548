import React, { useRef, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  IconButton,
  ClickAwayListener,
  Typography,
  Link,
  Snackbar,
  Alert,
} from "@mui/material";

import { useRoute, useLocation } from "wouter";

import useAnnotation from "../../../hooks/useAnnotation";
import useDefinitionStore from "../../../../stores/useDefinitionStore";

import Paragraphe from "../../notices/paragraphe";

import { convertNotation } from "../../../../outils";
import { use } from "react";

const FragmentAnnotation3DCard = (props) => {
  const { openNotice, openGallery, width, fragment } = props;
  const [match, params] = useRoute("/:section/:id");
  const [location, setLocation] = useLocation();

  const { isSuccess, definitions, annotations } = useAnnotation(fragment);

  const setCurrentCouche = useDefinitionStore(
    (state) => state.setCurrentCouche
  );
  const setActiveColor = useDefinitionStore((state) => state.setActiveColor);

  const clickedRef = useRef();
  const textRef = useRef();
  const hexRef = useRef();

  let loc = location.split("/");

  if (loc.length === 3) {
    switch (loc[1]) {
      case "annotations-3D":
        if (loc[2].split("-")[0] !== "annotation") {
          setLocation("/");
        } else {
          clickedRef.current = loc[2].split("-")[1];
          let index = clickedRef.current - 1;

          textRef.current = definitions?.[index];
          hexRef.current = convertNotation(
            annotations?.[index].index?.[`@_rendition`]?.split(" ")?.pop()
          );

          //   let res = convertNotation(
          //     annotations?.[index].index?.[`@_rendition`]?.split(" ")?.pop()
          //   );

          //   res.hex && setActiveColor(res.hex, res.couche);

          //   hexRef.current.couche &&
          //     setActiveColor(hexRef.current.hex, hexRef.current.couche);
        }

        break;
      case "materialite":
        console.log("materialite");
        break;
      case "iconographie":
        console.log("iconographie");
        break;
      default:
        break;
    }
  } else {
    clickedRef.current = "";
    textRef.current = null;
  }

  useEffect(() => {
    if (hexRef.current?.couche && hexRef?.current.hex) {
      //   setCurrentCouche(hexRef.current.couche);
      //   setActiveColor(hexRef.current.hex, hexRef.current.couche);
      useDefinitionStore.setState((state) => ({
        ...state,
        activeColor: {
          ...state.activeColor,
          [hexRef.current.couche]: hexRef.current.hex,
        },
        currentCouche: hexRef.current.couche,
        annotationActive: true,
        noticeActive: true,
        // mouseActive: true,
      }));
    }
  }, [hexRef.current?.couche, hexRef.current?.hex]);

  return (
    isSuccess &&
    definitions &&
    textRef.current && (
      <div
        id="annotation3D-space"
        style={{
          zIndex: 1500,
          position: "relative",
          width: openNotice || openGallery ? `calc(100% - ${width})` : `100%`,
          height: "100%",
          pointerEvents: "none",
        }}
      >
        <div
          id="annotation-card"
          // onMouseLeave={handleMouseLeave}
          // onMouseOver={handlePointerEnter}

          style={{
            position: "relative",
            top: `calc(50% + 10px)`,
            left: openNotice ? `calc(100% - ${width})` : `calc(50%)`,
            // transform: "translate(-50%, -50%)",
            maxWidth: "250px",
            pointerEvents: "auto",
            cursor: "pointer",
          }}
        >
          {/* <ClickAwayListener
        // onClickAway={handleClickAway}
        > */}
          <Card sx={{ backgroundColor: `rgb(0, 0, 0, 0.25)` }} elevation={0}>
            <CardHeader />
            <CardContent>
              {textRef.current.map((paragraphe, i) => (
                <Paragraphe
                  // ref={textRef}
                  key={paragraphe[`@_xml:id`]}
                  text={paragraphe[`#text`]}
                  id={paragraphe[`@_xml:id`]}
                />
              ))}

              <Typography></Typography>
            </CardContent>
            {/* <CardContent>
              <Typography color="primary">
                <Link>Mes documents associés</Link>
              </Typography>
            </CardContent> */}
            <CardActions></CardActions>
          </Card>
          {/* </ClickAwayListener> */}
        </div>
      </div>
    )
  );
};

export default FragmentAnnotation3DCard;

import React from "react";
import { Tooltip } from "@mui/material";

import parse, { attributesToProps, domToReact } from "html-react-parser";

import BulleReferenceBiblio from "./bulleReferenceBiblio";

import options from "../common/options";

// import BulleArchimageIIIF from "./bulleArchimageIIIF";

const TooltipReferenceBiblio = (props) => {
  const { id, text, reference } = props;

  let parsedReference = parse(reference, options);

  console.log(reference, parsedReference);

  return (
    <Tooltip
      key={id}
      title={
        parsedReference ? <BulleReferenceBiblio text={parsedReference} /> : null
      }
      arrow
      leaveDelay={250}
    >
      <mark className="biblio" id={`biblio-${id}`}>
        {text}
      </mark>
    </Tooltip>
  );
};

export default TooltipReferenceBiblio;

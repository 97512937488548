import React, { useState } from "react";

import { useQueries } from "../../../hooks";

const Vignette = (props) => {
  // const { image, error } = useLoadImage(props);
  const { setVignetteActive, vignetteActive, setAnnotationActive } = props;
  const [hovered, setHovered] = useState(false);

  const img_privee = "/images/img_privee.jpg";
  const img_blank = "/modeles/placeholders/geometry_blank.png";
  const size = 75;

  const handleClick = () => {
    setVignetteActive(props.id);
    setAnnotationActive(0);
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };

  const { data, isSuccess } = useQueries(
    `https://archimage.efa.gr/action.php?kroute=document_export_json_public&id=${props.archimageId}`
  );

  return (
    <img
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      // src={props.image ? (error ? img_privee : image) : img_blank}
      title={(isSuccess && data?.[`Référence`]) || "Référence manquante"}
      src={props.image}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        objectFit: "cover",
        borderRadius: "4px",
        outlineStyle: "solid",
        outlineColor:
          vignetteActive === props.id
            ? "orange"
            : hovered
            ? "orangeRed"
            : "darkOrange",

        outlineWidth:
          vignetteActive === props.id ? "2px" : hovered ? "2px" : "1px",
        opacity: vignetteActive === props.id ? 1.0 : 0.75,
        cursor: "pointer",
      }}
    />
  );
};

export default Vignette;

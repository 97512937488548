import React from "react";
import { Box, Typography, Divider, Stack, Button } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const BulleReferenceBiblio = (props) => {
  return (
    <Box sx={{ p: 1 }}>
      {props.text && (
        <>
          <Stack sx={{ pt: 1 }}>
            <Typography variant="body2" paragraph align="justify">
              {props.text}
            </Typography>
          </Stack>
          {/* <Divider /> */}
        </>
      )}
      {/* <Stack sx={{ pt: 1 }} direction="row" alignItems="end">
        <Button
          size="small"
          startIcon={<OpenInNewIcon fontSize="small" />}
          target="_blank"
          href={props.target}
          // variant="text"
        >
          archimage
        </Button>
      </Stack> */}
    </Box>
  );
};

export default BulleReferenceBiblio;

function parseExternalUrl(target) {
  const url = new URL(target);

  let domain = url.hostname.split(".")[0];
  let path = url.pathname.split("/");

  let manifestURL =
    "https://archimage.efa.gr/action.php?r=iiif_json_manifest&id=1074073";

  let manifest = {
    items: [
      {
        id: "https://archimage.efa.gr/action.php?r=iiif_json_manifest&id=1074073&item=id/canvas/p1",
        type: "Canvas",
        height: 2034,
        width: 1618,
      },
    ],
  };

  function setArchimageIIIF(params) {
    let { id } = params;

    let resultat = {
      id,
      thumbUrl: `https://${url.hostname}/action.php?kroute=image_preview_public&id=${id}&type=2&ext=.jpg`,
      ficheUrl: `https://${url.hostname}/?kroute=fiche_publique&id=${id}`,
    };

    switch (params.kroute) {
      case "fiche_publique":
        return {
          ...resultat,
          imgUrl: `https://${url.hostname}/action.php?kroute=image_preview_public&id=${id}&type=1&ext=.jpg`,
        };
    }

    switch (params.region) {
      case "full":
        return {
          ...resultat,
          imgUrl: `https://${url.hostname}/action.php?kroute=image_preview_public&id=${id}&type=1&ext=.jpg`,
        };

      default:
        let region = params.region.split(",").map(Number);
        let size = params.size.split(", ").map(Number);
        let { rotation, quality, format, kroute } = params;

        let [x, y, w, h] = region;
        let [width, height] = size;

        let ratio = h / w;
        let resolution = 800;
        let newSize =
          ratio > 1
            ? [Math.round(resolution / ratio, 0), resolution]
            : [resolution, Math.round(resolution * ratio, 0)];

        return {
          ...resultat,
          imgUrl: `https://${url.hostname}/index.php?kroute=${kroute}&id=${id}&region=${region}&size=${newSize}&rotation=${rotation}&quality=default&format=jpg`,
        };
    }

    return;
  }

  switch (domain) {
    case "archimage": {
      if (url.search) {
        let urlParams = new URLSearchParams(url.search);
        let params = Object.fromEntries(urlParams);
        // console.log(params);
        switch (params.kroute) {
          case "fiche_publique": {
            return setArchimageIIIF(params);
          }
          case "fiche_document": {
            console.log("fiche document");
            break;
          }
          case "recuperer_modele_3d": {
            console.log("3d");
          }
          case "image_request_iiif": {
            return setArchimageIIIF(params);
          }
        }
      }

      if (path[1] === "image_request_iiif") {
        let imgId = parseInt(path[2]);
        let imgUrlPreview = `https://archimage.efa.gr/action.php?kroute=image_preview_public&id=${imgId}&type=2&ext=.jpg`;
        let ficheUrl = `https://archimage.efa.gr/?kroute=fiche_publique&id=${imgId}`;

        let params = {
          kroute: path[1],
          id: path[2],
          region: path[3],
          size: path[4],
          rotation: path[5],
          quality: path[6],
          format: path[7],
        };

        return setArchimageIIIF(params);
      }
      break;
    }
  }
}

export default parseExternalUrl;

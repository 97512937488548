import React, { useState } from "react";
import * as THREE from "three";
import { Html } from "@react-three/drei";
import { useLocation, useRoute } from "wouter";

const Annotation3D = (props) => {
  const { index, obj, numero, position } = props;

  const [hidden, setHidden] = useState(true);

  const [, setLocation] = useLocation();
  const [match, params] = useRoute("/:section/:id");

  // match && console.log(params.id);

  function normalizePoint(target) {
    const vec = new THREE.Vector3(target[0], target[1], target[2]);
    const dir = vec.clone().normalize().multiplyScalar(0.0005);
    vec.add(dir);

    return [vec.x, vec.y, vec.z];
  }

  const handleClick = (e, id) => {
    console.log("click", id);
    e.stopPropagation();
    setLocation(`/annotations-3D/annotation-${id}`);
  };

  return (
    <Html
      key={index}
      name={`${numero}_annotation-${index + 1}`}
      position={normalizePoint(position)}
      distanceFactor={0.1}
      occlude={obj}
      onOcclude={setHidden}
    >
      <div
        className="annotations3D"
        style={{
          opacity:
            params?.id === `annotation-${index + 1}` ? 0 : hidden ? 0.4 : 1,
          transform: `translate(-50%, -50%) scale(${hidden ? 0.5 : 1})`,
          background: hidden ? "#ffffffcc" : "#ffffff33",
        }}
        onClick={(e) => handleClick(e, index + 1)}
      />
    </Html>
  );
};

export default Annotation3D;

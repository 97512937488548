import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLocation as useWocation, useRoute as useWoute } from "wouter";
import useHashLocation from "../hooks/useHashLocation";

const TemplateMetopes = (props) => {
  const { id, title, paths } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [loc] = useWocation();
  const [locHash] = useHashLocation();

  const [hovered, setHovered] = useState(false);
  const [selected, setSelected] = useState(false);

  const fill = hovered ? "orange" : selected ? "peru" : "grey";
  const stroke = hovered ? "orange" : selected ? "peru" : "white";
  const fillArchitrave = hovered ? "#ffffff33" : "#ffffff00";

  const handleClick = () => {
    navigate(`/tholos/${id}`);
  };

  useEffect(() => {
    let path = import.meta.env.VITE_NODE_ENV === "production" ? locHash : loc;
    if (path === `/tholos/${id}`) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [loc, locHash, location]);

  const handleMouseOver = (e) => {
    e.stopPropagation();
    setHovered(true);
  };
  const handleMouseOut = (e) => {
    e.stopPropagation();
    setHovered(false);
  };

  return (
    <g
      id={id}
      className="metopes"
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <path
        fill={fillArchitrave}
        className="architraves"
        id={`path-architraves-${id}`}
        d={paths.architrave}
      />
      <path
        className="metopes"
        id={`path-${id}`}
        fill={fill}
        stroke={stroke}
        transform="rotate(4.5)"
        d={paths.metopes}
      />
      <title>{title}</title>
    </g>
  );
};

export default TemplateMetopes;

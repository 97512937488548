import React from "react";
import parse, { attributesToProps, domToReact } from "html-react-parser";
import { Typography } from "@mui/material";
import useStyledXML from "../../hooks/useStyledXML";
import { xmlParserOptions } from "../bibliographieGenerale/xmlParserOptions";

import TooltipReferenceBiblio from "./ui/tooltipReferenceBiblio";

const ReferenceBiblio = (props) => {
  const { text } = props;

  const biblioGenerale = useStyledXML({
    xml: "bibliographie",
    xslt: "tableOfContent",
    expr: `$..body..bibl[*]`,
    parserOptions: xmlParserOptions,
  });

  // console.log(biblioGenerale);

  const options = {
    replace(domNode) {
      let { attribs, children, name } = domNode;

      if (!attribs) {
        return;
      }

      if (name === "seg") {
        let bibId = attribs[`xml:id`];

        let reference = biblioGenerale?.find((b, i) => b.id === bibId);

        return (
          <TooltipReferenceBiblio
            id={`#${bibId}`}
            text={domToReact(children, options)}
            reference={
              reference?.[`#text`] || "pas d'alignement avec bibliographie.xml"
            }
          />
        );
      }

      if (name === "hi") {
        switch (attribs.rend) {
          case "small-caps":
            return (
              <span className="biblioRef">{domToReact(children, options)}</span>
            );
        }
      }
    },
  };

  let parsedText = parse(text, options);

  return (
    <Typography variant="body2" align="justify" paragraph>
      {parsedText}
    </Typography>
  );
};

export default ReferenceBiblio;

import React, { useMemo, useState, useEffect } from "react";
import { XMLParser } from "fast-xml-parser";
import {
  Drawer,
  Box,
  Toolbar,
  Typography,
  Stack,
  IconButton,
  MobileStepper,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import { styled } from "@mui/material/styles";

import { parseExternalUrl } from "../../../../outils";
import useAnnotation2D from "../../../hooks/useAnnotation2D";

import Paragraphe from "../../notices/paragraphe";

import Zoom from "./zoom";
import BandeauVignettes from "./bandeauVignettes";

const MyDrawer = styled(Drawer)(() => ({
  overflow: "hidden",
  "& .MuiModal-backdrop": {
    backgroundColor: "rgba(0,0,0,0.0)",
  },
}));

const GalleryDrawer = (props) => {
  const { open, width, setOpenGallery, fragment } = props;

  const xmlParser = new XMLParser({
    ignoreAttributes: false,
    attributeNamePrefix: "",
  });

  let { isSuccess, images } = useAnnotation2D(fragment);

  const [vignetteActive, setVignetteActive] = useState(0);
  const [annotationActive, setAnnotationActive] = useState(0);

  const handleNext = () => {
    setAnnotationActive((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setAnnotationActive((prevActiveStep) => prevActiveStep - 1);
  };

  let MyVignettes = useMemo(
    () =>
      images?.map((i) =>
        parseExternalUrl(xmlParser.parse(i.p[0][`#text`]).ref.target)
      ),
    [isSuccess]
  );

  let MyImages = useMemo(() => {
    let mesPhotos = images?.map((img, index) => {
      let mesAnnot = img?.div?.map((div, idx) => {
        console.log(div?.p?.[0]?.[`#text`]);
        let urls = parseExternalUrl(
          xmlParser.parse(div?.p?.[0]?.[`#text`]).ref.target
        );

        let description = div?.p[1];

        let listNotation = div?.head?.index?.[`@_rendition`]?.split(" ")?.pop();

        return {
          image: urls.imgUrl,
          description: description,
          archimageId: urls.id,
          listNotation: listNotation,
        };
      });

      // let listNotation = img?.div?.[0]?.head?.index?.[`@_rendition`].split(" ");
      let urlIIIF = parseExternalUrl(
        xmlParser.parse(img.p?.[1]?.[`#text`]).ref.target
      );
      return {
        // listNotation: listNotation,
        urlIIIF: urlIIIF.imgUrl,
        annotations: mesAnnot,
      };
    });

    return mesPhotos;
  }, [isSuccess]);

  useEffect(() => {
    setAnnotationActive(0);
  }, [isSuccess]);

  return (
    <MyDrawer anchor="right" open={open} variant="persistent">
      <Toolbar variant="dense">
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Annotations 2D
        </Typography>
        <IconButton
          size="small"
          // sx={{ flexGrow: 1 }}
          onClick={() => setOpenGallery(false)}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>

      <Box
        sx={{
          width: `${width}`,
        }}
      >
        <Stack
          direction="column"
          spacing={2}
          sx={{ p: 2 }}
          justifyContent="space-around"
          // alignItems="center"
        >
          {isSuccess && MyVignettes && (
            <BandeauVignettes
              setVignetteActive={setVignetteActive}
              vignetteActive={vignetteActive}
              vignettes={MyVignettes}
              setAnnotationActive={setAnnotationActive}
            />
          )}

          {isSuccess && (
            <Zoom
              drawerOpen={open}
              annotationId={{
                image: vignetteActive + 1,
                annotation: annotationActive + 1,
              }}
              {...MyImages?.[vignetteActive]?.annotations?.[annotationActive]}
            />
          )}

          <Box sx={{ width: "100%", height: "60px" }}>
            {isSuccess &&
              MyImages?.[vignetteActive]?.annotations?.[annotationActive]
                ?.description?.[`#text`] && (
                <Paragraphe
                  text={
                    MyImages?.[vignetteActive]?.annotations?.[annotationActive]
                      ?.description?.[`#text`]
                  }
                  id={1}
                />
              )}
          </Box>
          {MyImages?.[vignetteActive]?.annotations?.length > 1 && (
            <MobileStepper
              variant="dots"
              activeStep={annotationActive}
              steps={MyImages?.[vignetteActive]?.annotations?.length}
              position="static"
              size="small"
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={annotationActive === 0}
                >
                  <KeyboardArrowLeft />
                </Button>
              }
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={
                    annotationActive ===
                    MyImages?.[vignetteActive]?.annotations?.length - 1
                  }
                >
                  <KeyboardArrowRight />
                </Button>
              }
            />
          )}
        </Stack>
      </Box>
    </MyDrawer>
  );
};

export default GalleryDrawer;

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

const Metope = (props) => {
  const { nodes, materials } = useGLTF(
    `/modeles/tholos/grande_metope_plaque.glb`
  );

  const group = useRef();
  const matRef = useRef();
  const meshRef = useRef();

  return (
    <group ref={group} {...props} dispose={null} position={[0, 0, 0]}>
      <mesh
        ref={meshRef}
        name={`mesh`}
        transparent
        opacity={1}
        castShadow
        receiveShadow
        geometry={nodes[`grande-metope-plaque`].geometry}
        position={[0, 0, 0]}
        // rotation={[-Math.PI, Math.PI / 10, -Math.PI]}
        visible={true}
      >
        <meshStandardMaterial
          name={`mat`}
          ref={matRef}
          transparent={true}
          opacity={1}
        />
      </mesh>

      <mesh>
        <boxGeometry args={[1, 1, 1]} />
        <meshBasicMaterial color="orange" opacity={0.25} transparent />
      </mesh>
    </group>
  );
};

export default Metope;

import React from "react";
import { Stack } from "@mui/material";

import GalleryItem from "./galleryItem";
import { GalleryAnnotation2D } from "./galleryAnnotation2D";

const GallerySection3 = (props) => {
  const { images } = props;

  // console.log(images?.map((i) => i.div?.map((p) => p.head)));
  // console.log(images?.map((i) => i));

  return (
    <Stack direction="column">
      <Stack direction="row">
        {images &&
          images.map((img, i) => (
            <GalleryItem text={img.p[0][`#text`]} key={img.p[0][`@_xml:id`]} />
          ))}
      </Stack>
      <Stack direction="row">
        {images &&
          images.map(
            (img, i) =>
              img.div &&
              img.head &&
              img.div.map((div, i) => (
                <GalleryAnnotation2D
                  key={i}
                  // text={div.p[0][`#text`]}
                  // key={div.p[0][`@_xml:id`]}
                  {...div}
                />
              ))

            //
          )}
      </Stack>
    </Stack>
  );
};

export default GallerySection3;

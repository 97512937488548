import React, { useState, useEffect } from "react";
import {
  Drawer,
  Box,
  Toolbar,
  Typography,
  Modal,
  Grid,
  Backdrop,
  IconButton,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const coef = 4;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: `calc(100% - ${coef}*24px)`,
  height: `calc(100% - ${coef}*24px)`,
  bgcolor: "background.paper",
  //   bgcolor: "background.default",
  //   bgcolor: "rgb(0.2 0.9 0.3 / 0.75)",
  padding: "12px 0px 12px 0px",
  borderRadius: "12px",
};

const GalleryModal = (props) => {
  const { open, setOpen } = props;

  const handleClose = () => {
    setOpen(false);
  };

  //   const [id, setId] = useState("intro");

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Toolbar variant="dense">
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="button">Annotations 2D</Typography>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Toolbar>
        <Grid container direction="row" alignItems="stretch"></Grid>
      </Box>
    </Modal>
  );
};

export default GalleryModal;

import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useMemo,
  useState,
  forwardRef,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "wouter";

import * as THREE from "three";

import { useFrame, useThree } from "@react-three/fiber";
import { useGLTF, CameraControls, useCursor, Html } from "@react-three/drei";

import {
  useOnBeforeCompile,
  usePosition,
  useQueries,
  useInterval,
} from "../../hooks";

import {
  vertexShaderReplacements,
  fragmentShaderReplacements,
} from "../../../shaders/fragment";

import useUiStore from "../../../stores/useUiStore";

import { setTextures } from "./setTextures";
import RenderTarget from "./renderTarget";
import Annotations from "./annotations/annotations";
import Annotations3D from "./annotations3D/annotations3D";

import useDefinitionStore from "../../../stores/useDefinitionStore";
import { setHexToRGB, convertNotationList } from "../../../outils";

const FragmentModel = (props) => {
  const {
    filename,
    fitToSphere,
    couche,
    rotationFragment,
    annotationsOn,
    setOpenNotice,
    openNotice,
  } = props;
  const { viewport, pointer, mouse } = useThree();
  const navigate = useNavigate();

  const { DEG2RAD } = THREE.MathUtils;

  const [rotX, rotY, rotZ] = rotationFragment.map((item) =>
    THREE.MathUtils.degToRad(item)
  );

  const { data: annotations, isSuccess: isAnnotations } = useQueries(
    `/json/annotations/${filename}.json`
  );

  const {
    data: dataFragments,
    isSuccess: isSuccessFragments,
    isLoading: isLoadingFragments,
  } = useQueries(`/json/annotationsParFragment.json`);

  // console.log(annotationsOn);

  const coucheActive = {
    materialite: 0.0,
    iconographie: 1.0,
  };

  const paramsFragment = useUiStore((state) => state.paramsFragment);
  const mixFactor = paramsFragment.diffuse.value;
  const nScaleFactor = paramsFragment.normale.value;

  const currentCouche = useDefinitionStore((state) => state.currentCouche);

  const currentColor = useDefinitionStore(
    (state) => state?.activeColor?.[currentCouche]
  );
  const previousColor = useDefinitionStore(
    (state) => state?.previousColor?.[currentCouche]
  );

  const setActiveColor = useDefinitionStore((state) => state.setActiveColor);
  const setPreviousColor = useDefinitionStore(
    (state) => state.setPreviousColor
  );

  const definitions = useDefinitionStore((state) => state[currentCouche]);

  const mouseActive = useDefinitionStore((state) => state.mouseActive);
  const noticeActive = useDefinitionStore((state) => state.noticeActive);
  const annotationActive = useDefinitionStore(
    (state) => state.annotationActive
  );
  const setMouseActive = useDefinitionStore((state) => state.setMouseActive);

  const setTooltip = useDefinitionStore((state) => state.setTooltip);

  const [entered, setEntered] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [pointerDown, setPointerDown] = useState(false);
  const [cameraActive, setCameraActive] = useState();

  const [goal, setGoal] = useState(0);
  const [idle, setIdle] = useState(false);

  const [animation, setAnimation] = useState(false);

  const [popup, setPopup] = useState(false);
  const [delayedEvent, setDelayedEvent] = useState(false);

  const [tooltipPos, setTooltipPos] = useState([0, 0, 0]);

  useCursor(hovered);

  const [triggerFitToSphere, setTriggerFitToSphere] = useState();

  const [, setLocation] = useLocation();

  let opacity = useMemo(() => {
    return { value: 0.0 };
  }, [currentColor, previousColor]);

  /**
   *
   */

  var rootToModel = `/modeles/${filename}/${filename}`;
  var pathToModel = `${rootToModel}.glb`;
  var pathToDraco = "/draco/";

  /**
   *  REFs
   */
  const group = useRef();
  const meshRef = useRef();
  const matRef = useRef();
  const renderTargetRef = useRef();
  const rtMaterialiteRef = useRef();
  const rtIconographieRef = useRef();
  const cameraControlsRef = useRef();
  const tooltipRef = useRef();
  const clickedRef = useRef();

  /**
   * TEXTURES
   */

  const { map, normalMap, emissiveMap, materialite, iconographie } =
    setTextures(rootToModel);

  const normalScale = new THREE.Vector2(2, 2).multiplyScalar(nScaleFactor);
  const emissiveIntensity = 5.0;

  const couches = { materialite, iconographie };

  /**
   * COLORS
   */

  const diffuse = "#ffffff";
  const color = "#ffffff";
  const emissive = "#ffffff";
  const defaultColor = 0xffffff;

  /**
   * TIME
   */

  const time = useMemo(() => {
    return { value: 0.0 };
  }, []);

  const mouseUV = useMemo(() => {
    return { value: new THREE.Vector2(0.0, 0.0) };
  }, []);

  const mouseRef = useRef({ value: new THREE.Vector2(0.0, 0.0) });

  const mouseUVRef = useRef({ value: new THREE.Vector2(0.0, 0.0) });

  function convertNotation(list) {
    let excluded = ["ffffff", "000000"];

    return (
      list
        .map((n) => n.slice(1, 7))
        // .filter((n) => n != "ffffff");
        .filter((n) => !excluded.includes(n))
    );
  }

  const myAnnotations = useMemo(() => {
    return {
      materialite: convertNotationList(
        dataFragments?.materialite?.[filename] || []
      ),
      iconographie: convertNotationList(
        dataFragments?.iconographie?.[filename] || []
      ),
    };
  }, [filename]);

  const currentCursor = useMemo(() => new THREE.Vector2(2000, 2000));
  const previousCursor = useMemo(() => new THREE.Vector2(2000, 2000));

  const alpha = useRef();

  useFrame((state, delta) => {
    const { clock } = state;

    time.value = clock.getElapsedTime();

    // const frameDelta = delta * 10;

    // console.log(Math.round(frameDelta));

    // !hovered
    //   ? cameraControlsRef.current?.rotate(
    //       Math.sin(time.value * 0.5 + 0.5) * 0.0015,
    //       Math.cos(time.value * 0.5 + 0.5) * 0.00075,
    //       true
    //     )
    //   : null;

    // !hovered ?
    //  c =

    // : null

    if (hovered || noticeActive || annotationActive) {
      opacity.value = THREE.MathUtils.lerp(opacity.value, 1.0, 0.15);

      // currentCursor.copy(mouse)
      // let cursorDistance = previousCursor.distanceTo(currentCursor)
      // alpha.current =  Math.min(cursorDistance * 0.1, 1)
      // alpha.current  <= 0.01 ? setPopup(true) : setPopup(false)
      // previousCursor.copy(currentCursor)
    }

    // if (!hovered) {
    //   opacity.value = THREE.MathUtils.lerp(opacity.value, 0.0, 0.15);
    // }
  });

  /**
   *  MODEL LOADER
   */

  const model = useGLTF(pathToModel, pathToDraco);
  const { nodes } = model;

  const listMeshes = Object.values(nodes).filter((node) => node.isMesh);

  /**
   *  OBJECT CENTER
   */
  let radius, center;
  if (meshRef?.current?.geometry?.boundingSphere) {
    radius = meshRef.current.geometry.boundingSphere.radius;
    center = meshRef.current.geometry.boundingSphere.center;
  } else {
    radius = 0.3;
    center = { x: 0, y: 0, z: 0 };
  }

  /**
   *  USEEFFECTS
   */

  /**
   * ZOOM TO OBJECT
   */

  useEffect(() => {
    cameraControlsRef.current.fitToSphere(group.current, true);
  }, [viewport.aspect, fitToSphere, triggerFitToSphere]);

  /**
   *  TIMEOUT
   */

  useEffect(() => {
    let reset = false;
    if (!hovered) {
      setTimeout(() => setAnimation(true), 5000);

      reset = true;
    }
    return () => {
      reset && setAnimation(false);
    };
  }, [!hovered]);

  // let c = useInterval(1, [idle]);

  // useEffect(() => {
  //   entered ? setIdle(false) : setTimeout(() => setIdle(true), 5000);

  //   if (idle && !entered) {
  //     useDefinitionStore.setState((state) => ({
  //       ...state,
  //       activeColor: {
  //         ...state.activeColor,
  //         [currentCouche]: myAnnotations[currentCouche][c],
  //       },
  //       mouseActive: true,
  //     }));
  //   }
  // }, [c]);

  /**
   *  USEPOSITION
   */

  usePosition(cameraControlsRef.current, filename, annotations);

  /**
   *  SHADER
   */

  const uniforms = {
    color: { value: new THREE.Color(color) },
    uActiveColor: {
      value: new THREE.Color(parseInt(`0x${currentColor}`)),
    },
    uPreviousColor: {
      value: new THREE.Color(parseInt(`0x${previousColor}`)),
    },
    uTime: time,
    uDiffuse: { value: new THREE.Color(diffuse) },
    uEmissive: { value: new THREE.Color(emissive) },
    uShade: { value: new THREE.Color(0xcccccc) },
    map: { value: map },
    uMixFactor: { value: 1 - mixFactor },
    normalMap: { value: normalMap },
    normalScale: { value: normalScale },
    uEmissiveMap: { value: emissiveMap },
    uEmissiveIntensity: { value: emissiveIntensity },
    uMixCouche: { value: coucheActive[currentCouche] },
    uMaterialite: { value: materialite },
    uIconographie: { value: iconographie },
    uMouseUV: mouseUV,
    uMouseActive: { value: mouseActive ? 1.0 : 0.0 },
    uOpacity: opacity,
  };

  const dependencies = [
    mixFactor,
    map,
    nScaleFactor,
    color,
    defaultColor,
    time,
    emissive,
    diffuse,
    coucheActive[currentCouche],
    currentCouche,
    currentColor,
    previousColor,
    materialite,
    iconographie,
    mouseUV.value.x,
    mouseUV.value.y,
    mouseActive,
    noticeActive,
    annotationActive,
    opacity,
  ];

  const defines = {
    // USE_COLOR: true,
    // USE_UV: false,
    USE_MAP: true,
    USE_NORMALMAP: true,
    USE_EMISSIVE: true,
    USE_EMISSIVEMAP: false,
    USE_TANGENT: false,
    USE_CLEARCOAT_NORMALMAP: false,
    // TANGENTSPACE_NORMALMAP: true,
    // OBJECTSPACE_NORMALMAP: false,
    TANGENTSPACE_NORMALMAP: true,
    OBJECTSPACE_NORMALMAP: false,
    FLAT_SHADED: false,
    FLIP_SIDED: false,
    DOUBLE_SIDED: false,
    USE_BUMPMAP: false,
    HIGH_PRECISION: false,
    DITHERING: false,
    USE_ENVMAP: false,
    ENVMAP_TYPE_CUBE: false,
    TONE_MAPPING: false,
  };

  const OBC = useOnBeforeCompile(
    vertexShaderReplacements,
    fragmentShaderReplacements,
    uniforms,
    dependencies
  );

  useEffect(
    () =>
      void (matRef.current.dispose(),
      (matRef.current.needsUpdate = true),
      (matRef.current.forceSinglePass = true),
      (matRef.current.onBeforeCompile = OBC)),

    [OBC]
  );

  /**
   *  HANDLERS
   **/

  const onMouseOver = (e) => {
    e.stopPropagation();

    setMouseActive(true);
    setEntered(true);

    // switch (currentColor) {
    //   case "ffffff":
    //     // console.log("couleur neutre");
    //     setHovered(false);
    //     break;
    //   case "000000":
    //     // console.log("couleur neutre");
    //     setHovered(false);
    //     break;
    //   case "ff1493":
    //     // console.log("pas de couche");
    //     break;
    //   default:
    //     setHovered(true);
    //   // popup ? myPopup(e): console.log("no popup")
    // }
  };

  const onMouseOut = (e) => {
    e.stopPropagation();
    setEntered(false);
    setHovered(false);
    setPointerDown(false);
    useDefinitionStore.setState((state) => ({
      ...state,
      activeColor: { ...state.activeColor, [currentCouche]: "ffffff" },
      previousColor: { ...state.previousColor, [currentCouche]: currentColor },
      mouseActive: false,
    }));
  };

  const onMouseMove = useCallback(
    (e) => {
      e.stopPropagation();
      const { uv, pointer, point } = e;
      mouseRef.current.value = pointer;
      // mouseUV.value = uv;
      mouseUVRef.current.value = uv;
      currentColor === "ffffff" || currentColor === "000000"
        ? setHovered(false)
        : setHovered(true);
    },
    [currentColor]
  );

  const onMouseDown = (e) => {
    e.stopPropagation();
    setPointerDown(true);
    // console.log("down");
  };
  const onMouseUp = (e) => {
    e.stopPropagation();
    setPointerDown(false);
    // console.log("up");
  };

  const onPointerMissed = (e) => {
    e.stopPropagation();
    setLocation("");

    if (e.type === "dblclick") {
      setOpenNotice(false);
      // console.log("double click");
    }

    setTriggerFitToSphere(!triggerFitToSphere);
    setTooltip(-500, -500, false);
  };

  function myPopup(e) {
    let myDefinition = definitions
      .filter((d) => d.hex.slice(1, 7) === currentColor)
      .map((o) => {
        return {
          hex: o.hex,
          descripteur: o.descripteur,
        };
      });

    // tooltipRef.current.style.visibility = hovered ? "visible" : "hidden";
    // tooltipRef.current.style.opacity = hovered ? "1" : "0";
    // tooltipRef.current.innerHTML = myDefinition[0].descripteur;

    let { point } = e;
    setTooltipPos([point.x, point.y, point.z]);
  }

  const onContextMenu = (e) => {
    e.stopPropagation();

    let { point } = e;

    let { x, y, z } = cameraControlsRef.current.getPosition();

    let coordAnnotation = {
      position: [x, y, z],
      target: [point.x, point.y, point.z],
    };

    hovered &&
    currentColor &&
    currentColor != "ffffff" &&
    currentColor != "000000"
      ? setTooltip(e.x, e.y, true, currentColor, coordAnnotation)
      : setTooltip(-500, -500, true, currentColor);
  };

  const onClick = (e) => {
    e.stopPropagation();

    switch (currentColor) {
      case "ffffff":
        console.log("couleur neutre");
        break;
      case "000000":
        console.log("couleur neutre");
        break;
      case "ff1493":
        console.log("pas de couche");
        break;
      default:
        clickedRef.current = { currentCouche, currentColor };
        setLocation(`/${currentCouche}/${currentColor}`);
    }
  };

  const onDoubleClick = (e) => {
    e.stopPropagation();

    switch (currentColor) {
      case "ffffff":
        // console.log("couleur neutre");
        break;
      case "000000":
        // console.log("couleur neutre");
        break;
      case "ff1493":
        // console.log("pas de couche");
        break;
      default:
        navigate(`/fragments/${currentCouche}/${currentColor}`);
    }
  };

  useGLTF.preload(pathToModel, pathToDraco);

  return (
    <>
      {/* <MyFakeComponent /> */}
      <group
        {...props}
        ref={group}
        name={filename}
        rotation={[rotX, rotZ, rotY]}
        onPointerOver={onMouseOver}
        onPointerOut={onMouseOut}
        onPointerMove={onMouseMove}
        onPointerDown={onMouseDown}
        onPointerUp={onMouseUp}
        onContextMenu={onContextMenu}
        onPointerMissed={onPointerMissed}
        // onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        {listMeshes.map((mesh, index) => (
          <mesh
            ref={meshRef}
            castShadow
            receiveShadow
            key={index}
            name={mesh.name}
            geometry={mesh.geometry}
            position={mesh.position}
            rotation={mesh.rotation}
          >
            <meshStandardMaterial
              flatShading={false}
              name={`${filename}_mat`}
              ref={matRef}
              onBeforeCompile={OBC}
              defines={defines}
              forceSinglePass
              needsUpdate
            />
          </mesh>
        ))}
        {/* <RenderTarget
          ref={renderTargetRef}
          map={couches[currentCouche]}
          maps={couches}
          mouseActive={mouseActive}
          // mouse={mouseUV}
          pointerDown={pointerDown}
          mouse={mouseUVRef.current}
          couche={currentCouche}
        /> */}

        {}

        <RenderTarget
          id="iconographie"
          ref={rtIconographieRef}
          map={couches.iconographie}
          maps={couches}
          mouseActive={mouseActive}
          // mouse={mouseUV}
          pointerDown={pointerDown}
          mouse={mouseUVRef.current}
          couche={currentCouche}
        />
        <RenderTarget
          id="materialite"
          ref={rtMaterialiteRef}
          map={couches.materialite}
          maps={couches}
          mouseActive={mouseActive}
          // mouse={mouseUV}
          pointerDown={pointerDown}
          mouse={mouseUVRef.current}
          couche={currentCouche}
        />

        <CameraControls
          ref={cameraControlsRef}
          smoothTime={0.4}
          draggingSmoothTime={0.1}
        />
      </group>

      {annotationsOn && definitions.length > 0 ? (
        <Annotations
          {...props}
          center={center}
          radius={radius}
          setMouseActive={setMouseActive}
        />
      ) : null}

      {isAnnotations && !openNotice ? (
        <Annotations3D numero={filename} obj={meshRef} />
      ) : null}
    </>
  );
};

export default FragmentModel;

import React, { useState, useEffect } from "react";
import { Tooltip, Link, Menu, MenuItem } from "@mui/material";
import useDefinitionStore from "../../../../stores/useDefinitionStore";
import useQueryDefinitions from "../../../hooks/useQueryDefinitions";

// import BulleArchimageIIIF from "./bulleArchimageIIIF";

const TooltipAnnotation = (props) => {
  const { id, text, couche, hex } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const currentCouche = useDefinitionStore((state) => state.currentCouche);
  const setCurrentCouche = useDefinitionStore(
    (state) => state.setCurrentCouche
  );
  const setActiveColor = useDefinitionStore((state) => state.setActiveColor);
  const setPreviousColor = useDefinitionStore(
    (state) => state.setPreviousColor
  );

  const activeColor = useDefinitionStore((state) => state.activeColor);
  const previousColor = useDefinitionStore((state) => state.previousColor);

  const mouseActive = useDefinitionStore((state) => state.mouseActive);
  const setMouseActive = useDefinitionStore((state) => state.setMouseActive);

  const noticeActive = useDefinitionStore((state) => state.noticeActive);
  const setNoticeActive = useDefinitionStore((state) => state.setNoticeActive);

  const { data: defs } = useQueryDefinitions(`palettes/${couche}`);

  // defs ? console.log(defs) : null;

  // let child = text[1].children[0].data;
  let child = props.children;
  // console.log(text);

  // const [hovered, setHovered] = useState(false);

  const handleMouseEnter = (e) => {
    e.stopPropagation();
    setCurrentCouche(couche);
    setNoticeActive(true);
    setMouseActive(true);
    // console.log("over");
    // console.log(hex, child);
    setActiveColor(hex, couche);
    // setHovered(true);
  };

  const handleMouseLeave = (e) => {
    e.stopPropagation();
    // setPreviousColor(hex, couche)

    setActiveColor("000000", couche);
    // console.log("out");
    setNoticeActive(false);
    setMouseActive(false);
    // setHovered(false);
  };

  const handleMouseOver = (e) => {
    e.stopPropagation();
    setNoticeActive(true);
    setMouseActive(true);
    console.log("over");
    console.log(hex, child);
    setActiveColor(hex, couche);
  };

  const handleMouseOut = (e) => {
    e.stopPropagation();
    // setPreviousColor(hex, couche)
    setActiveColor("000000", couche);
    console.log("out");
    setNoticeActive(false);
    setMouseActive(false);
  };

  const handleContextMenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
    // console.log("context");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Link
        underline="none"
        // onMouseEnter={() => console.log(`${couche} : ${hex}`)}

        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onContextMenu={handleContextMenu}
        // className="notice-annotation"
        // onMouseOver={handleMouseOver}
        // onMouseOut={handleMouseOut}
      >
        {child}
      </Link>
      <Menu id="menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleClose}>Option 1</MenuItem>
        <MenuItem onClick={handleClose}>Option 2</MenuItem>
      </Menu>
    </>

    // <Tooltip key={id} title={couche} arrow leaveDelay={250}>
    //   <a>
    //     <dfn title={`${couche} : ${hex}`}>test</dfn>
    //   </a>
    // </Tooltip>
  );
};

export default TooltipAnnotation;

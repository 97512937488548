import React from "react";
import * as THREE from "three";
import { Canvas, useThree } from "@react-three/fiber";

import { MyLights } from "../../fragment";
import Lights from "../../tholos/lights";

import Metope from "./metope";

const GrandesMetopes = () => {
  return (
    <div
      className="webgl"
      id="scene3D-grandes-metopes"
      style={{ width: "100%" }}
    >
      <Canvas
        flat
        legacy={true}
        linear={true}
        shadows
        dpr={[1, 2]}
        camera={{
          fov: 20,
          near: 0.01,
          far: 40,
          position: [0, 1, 15],
        }}
      >
        {/* <MyLights /> */}
        <Lights />

        <Metope />
      </Canvas>
    </div>
  );
};
export default GrandesMetopes;

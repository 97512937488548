import React, { useEffect, useState } from "react";
import { XMLParser } from "fast-xml-parser";
import parse, { attributesToProps, domToReact } from "html-react-parser";
import * as jp from "jsonpath";

const useTableOfContent = () => {
  const [toc, setToc] = useState(null);

  // const xmlUrl = "/xml/tableOfContent.xml";
  const xmlUrl = "/xml/texteGeneral.xml";
  const xslUrl = "/xml/tableOfContent.xslt";

  /**
   * Functions
   */

  function httpRequest(method, url, content, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.addEventListener("readystatechange", function () {
      if (xhr.readyState !== 4 || xhr.status !== 200) {
        return;
      }
      if (!!callback && typeof callback === "function") {
        callback(xhr);
      }
    });

    xhr.send(content);
  }

  function setTagName(tagName) {
    switch (tagName) {
      case "div":
        return "children";
      case "head":
        return "label";

      default:
        return tagName;
    }
  }

  function formatSection1(txt) {
    // let text = txt.replace(/-.*:/, "-"); // probleme avec style='font-variant:small-caps'
    let text = txt.replace(/-.* :/, "-");

    return text;
  }
  function formatSection2(txt) {
    let text = txt.split(" : ").length > 1 ? txt.split(" : ")[1] : txt;

    return text;
  }

  function htmlTransform(html) {
    return parse(html, {
      transform(reactNode, domNode, index) {
        if (!domNode.attribs) {
          return domNode.data;
        }
        if (domNode.type === "tag" && domNode.name === "hi") {
          switch (domNode.attribs.rend) {
            case "italic":
              domNode.name = "em";
              break;
            case "sup":
              domNode.name = "sup";
              // reactNode.type = "sup";

              break;
            case "sc":
              (domNode.name = "span"),
                (domNode.attribs.style = "font-variant:small-caps");
              break;
          }
          let { rend, ...others } = domNode.attribs;
          domNode.attribs = { ...others };
          //let xmls = new XMLSerializer();
          //let myHTML = xmls.serializeToString(domNode);
          // console.log(domNode);
          // reactNode.props = { ...others };
        }
        // console.log(reactNode);

        return domNode;
        //console.log(domNode);
      },
    });
  }

  function processTagValue(tagValue, jPath) {
    function select(tagValue, jPath) {
      switch (jPath) {
        case "TEI.text.body.children.label":
          return formatSection1(tagValue);

        case "TEI.text.body.children.children.label":
          return formatSection2(tagValue);

        default:
          return tagValue;
      }
    }

    let text = select(tagValue, jPath);

    return text;
  }

  /**
   * HTML PARSER
   */

  const htmlParserOptions = {
    replace(domNode) {
      let { attribs, children, name } = domNode;

      if (!attribs) {
        return <div>{children}</div>;
      }

      if (name === "hi") {
        switch (attribs.rend) {
          case "italic":
            return <em>{domToReact(children, htmlParserOptions)}</em>;
          case "sup":
            return <sup>{domToReact(children, htmlParserOptions)}</sup>;
          case "sc":
            return (
              <span style="font-variant:small-caps">
                {domToReact(children, htmlParserOptions)}
              </span>
            );
          default:
            return <span>{children}</span>;
        }
      }
    },
  };

  /**
   * XML PARSER
   */

  const xmlParserOptions = {
    ignoreAttributes: false,
    attributeNamePrefix: "",
    trim: true,
    stopNodes: ["*.p", "*.head", "*.label"],
    processEntities: true,
    ignoreDeclaration: true,
    ignorePiTags: true,
    removeNSPrefix: true,
    isArray: (name, jpath, isLeafNode, isAttribute) => {
      if (["children", "div"].indexOf(name) !== -1) return true;
    },
    transformTagName: (tagName) => setTagName(tagName),
    // attributeValueProcessor: (name, val, jPath) => {
    //   console.log(name, val);
    // },

    tagValueProcessor: (tagName, tagValue, jPath, hasAttributes, isLeafNode) =>
      (tagValue = processTagValue(tagValue, jPath)),
  };
  const xmlParser = new XMLParser(xmlParserOptions);

  /**
   * XML PARSER SECONDAIRE
   */

  /**
   * HOOK
   */

  /**
   *  XML ONLY
   */

  // useEffect(() => {
  //   var xhr = new XMLHttpRequest();
  //   xhr.addEventListener("load", () => {
  //     let parsedData = xmlParser.parse(xhr.response);
  //     let myToc = jp.query(parsedData, `$..[?(@.type=="section1")]`);
  //     setToc(myToc);
  //   });
  //   xhr.open("GET", `/xml/tableOfContent.xml`);
  //   xhr.send();
  // }, []);

  /**
   *  XML AND XSLT
   */

  /**
   *  injection manuelle d'un entête pour la bibliographie
   *  dans le cas où elle n'est pas présente dans le fichier xml ;
   *  le corps de la bibliographie devra également être parsé séparément
   *  au texte général
   */

  const biblio = {
    label: "Bibliographie",
    subtype: "bibliographie",
    type: "section1",
    id: "div99",
  };

  useEffect(() => {
    httpRequest("GET", xmlUrl, void 0, function (xml) {
      const xmlObject = xml.responseXML;
      httpRequest("GET", xslUrl, void 0, function (xsl) {
        const xslObject = xsl.responseXML;
        const xsltProcessor = new XSLTProcessor();
        xsltProcessor.importStylesheet(xslObject);
        const resultDocument = xsltProcessor.transformToFragment(
          xmlObject,
          document
        );
        const xmlSerializer = new XMLSerializer();
        const serializedData = xmlSerializer.serializeToString(resultDocument);
        let parsedData = xmlParser.parse(serializedData);
        let myToc = jp.query(parsedData, `$..[?(@.type=="section1")]`);
        let myExtendedToc = [...myToc, biblio];
        setToc(myExtendedToc);
      });
    });
  }, []);

  return toc;
};
export default useTableOfContent;
